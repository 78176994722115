import React, { useState } from 'react';
import { randomID } from '../../particles/helpers';
import styled from '@emotion/styled';
import theme from '../../particles/Theme';
import SubHeading from '../../atoms/SubHeading/SubHeading';
import { GatsbyImage } from "gatsby-plugin-image";
import Lightbox from 'react-image-lightbox';
import 'react-image-lightbox/style.css';

const StyledSection = styled.section`
  margin-bottom: 5rem;

  .collection-description {
    margin: 5rem 0 0;
    display: grid;
    grid-template-columns: 150px 1fr;
    grid-gap: 3rem;

    @media (max-width: ${theme.breakpoints[0]}) {
      grid-template-columns: 1fr;
      text-align: center;
    }

    .collection-img {
      max-width: 150px;
      margin-bottom: 10px;

      @media (max-width: ${theme.breakpoints[0]}) {
        margin: 0 auto 10px;
      }
    }
  }

  .collection-swatches {
    display: grid;
    margin-top: 5rem;
    grid-template-columns: repeat(6, 1fr);
    grid-gap: 5rem;

    @media (max-width: ${theme.breakpoints[2]}) {
      grid-template-columns: repeat(5, 1fr);
    }

    @media (max-width: ${theme.breakpoints[1]}) {
      grid-template-columns: repeat(3, 1fr);
    }

    @media (max-width: ${theme.breakpoints[0]}) {
      grid-template-columns: repeat(2, 1fr);
    }

    @media (max-width: 400px) {
      grid-template-columns: repeat(1, 1fr);
    }

    figure {
      margin: 0;
      cursor: pointer;

      figcaption {
        padding-top: 5px;
        font-size: 2rem;

        span {
          font-weight: 700;
          color: ${theme.colors.primary};
          margin-right: 5px;
        }

      }
    }
  }
`

const Collections = ({ designOptions, images, captions }) => {
  let [isOpen, setOpen] = useState(false)
  let [photoIndex, setPhotoIndex] = useState(0)
  let imgIdx = 0;
  return <>
    {designOptions.map(option => (
      <StyledSection key={randomID()}>
        <SubHeading title={option.title} />

        {option.designOptions.designImagelogo ? (
          <section className="collection-description">
            <div>
              <GatsbyImage
                alt={option.designOptions.designImagelogo.altText}
                image={option.designOptions.designImagelogo.localFile.childImageSharp.gatsbyImageData}
                className="collection-img" />
              {option.designOptions.flyer ? (
                <a
                  href={option.designOptions.flyer.localFile.publicURL}
                  target="_blank"
                  style={{ marginTop: `5px` }}
                  rel="noopener noreferrer"
                >
                  Download Flyer
                </a>
              ) : null}
            </div>
            <article className="collection-info">
              <div
                dangerouslySetInnerHTML={{
                  __html: option.designOptions.description,
                }}
              />
            </article>
          </section>
        ) : option.designOptions.description ? (
          <article className="collection-info">
            <div
              dangerouslySetInnerHTML={{
                __html: option.designOptions.description,
              }}
            />
          </article>
        ) : null}
        <section className="collection-swatches">
          {option.designOptions.swatches.map(swatch => {
            let idx = imgIdx++

            if (swatch.image) {
              return (
                <figure
                  key={randomID()}
                  onClick={() => {
                    setPhotoIndex(idx)
                    setOpen(true)
                  }}
                >
                  <GatsbyImage alt={swatch.image.altText} image={swatch.image.localFile.childImageSharp.gatsbyImageData} />
                  <figcaption>
                    <span>{swatch.swatchNumber}</span> {swatch.name}
                  </figcaption>
                </figure>
              );
            } else {
              return (
                null
              )
            }
          })}
        </section>
      </StyledSection>
    ))}
    {isOpen && (
      <Lightbox
        mainSrc={images[photoIndex]}
        nextSrc={images[(photoIndex + 1) % images.length]}
        prevSrc={images[(photoIndex + images.length - 1) % images.length]}
        imageCaption={captions[photoIndex]}
        onCloseRequest={() => setOpen(false)}
        onMovePrevRequest={() =>
          setPhotoIndex((photoIndex + images.length - 1) % images.length)
        }
        onMoveNextRequest={() =>
          setPhotoIndex((photoIndex + 1) % images.length)
        }
      />
    )}
  </>;
};

export default Collections;