import React from 'react';
import {graphql} from 'gatsby';
import Base from '../components/templates/Base';
import Banner from '../components/organisms/banner/Banner';
import Content from '../components/particles/Content';
import theme from "../components/particles/Theme"
import Collections from '../components/organisms/Collections/Collections';

const designOptionsPage = ({ data }) => {
  let designOptions = data.allWpDesignOption.nodes

  const images = []
  const captions = []

  designOptions.forEach(option => {
    option.designOptions.swatches.forEach(swatch => {
      images.push(swatch.image.localFile.childImageSharp.gatsbyImageData.images.fallback.src)
      captions.push(`${swatch.swatchNumber} ${swatch.name}`)
    })
  })

  return (
    <Base title="Design Options">
      <Banner title="Design Options" />
      <Content style={{ marginBottom: `5rem`, marginTop: `5rem` }}>
        <section style={{ textAlign: `center`,maxWidth: `90%`, margin: `0 auto 5rem`, paddingBottom: `4rem`, borderBottom: `2px solid ${theme.colors.secondary}` }}>
          <h3 style={{ color: theme.colors.primary }}>Innovation in design, simplicity in execution.</h3>
          <p style={{ maxWidth: `800px`, margin: `0 auto` }}>You can be confident that when you choose Surface Synergies, you will find an eclectic collection of innovative designs, offering Thermally Fused Melamine, Thermal Foil, High Pressure Laminates, and Edge Banding... all matched to exacting standards.</p>
        </section>
        <Collections designOptions={designOptions} images={images} captions={captions} />
      </Content>
    </Base>
  )
};

export const query = graphql`query getDesignOptions {
  allWpDesignOption(sort: {fields: date, order: DESC}) {
    nodes {
      title
      designOptions {
        description
        flyer {
          mediaItemUrl
          localFile {
            publicURL
          }
        }
        designImagelogo {
          altText
          sourceUrl
          localFile {
            relativePath
            childImageSharp {
              gatsbyImageData(width: 800, layout: CONSTRAINED)
            }
          }
        }
        swatches {
          name
          swatchNumber
          image {
            altText
            sourceUrl
            localFile {
              childImageSharp {
                gatsbyImageData(layout: FULL_WIDTH)
              }
            }
          }
        }
      }
    }
  }
}
`

 export default designOptionsPage;